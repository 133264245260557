<template>
  <div class="mx-8">
    <v-subheader class="py-0 d-flex justify-space-between">
      <h3>DATA RKAKL</h3>
    </v-subheader>

    <div class="mb-2"></div>

    <v-row>
      <v-col cols="12" sm="4" md="3">
        <v-select
          v-model="filter"
          :items="filterOptions"
          outlined
          label="Tahun"
          hide-details
          @change="init()"
        ></v-select>
      </v-col>
    </v-row>

    <div class="mb-4"></div>

    <v-data-table
      :headers="headers"
      :items="infoListRKBMN.data"
      disable-sort
      hide-default-footer
    >
      <template v-slot:item.detail="{ item }">
        <v-btn class="primary" small @click="toDetailPage(item)">Lihat</v-btn>
      </template>

      <template v-slot:no-data>
        <label>Tidak ada data</label>
      </template>

      <template v-slot:footer>
        <v-divider class="mt-6"></v-divider>
        <v-row class="mt-3 mb-3" align="center" justify="end">
          <span class="caption">Limit:</span>
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn dark text color="primary" class="ml-2" v-on="on">
                {{ limit }}
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(number, index) in limitArray"
                :key="index"
                @click="updateLimit(number)"
              >
                <v-list-item-title>{{ number }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <span class="caption ml-4 mr-4"
            >Total Data: {{ infoListRKBMN.jumlahdata }}</span
          >
          <span class="caption ml-4 mr-4"
            >Page {{ page }} of {{ infoListRKBMN.jumlahhalaman }}</span
          >
          <v-btn small color="primary" class="mr-4" @click="prevPage">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn small color="primary" class="mr-4" @click="nextPage">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-row>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      params: "",
      page: 1,
      limit: 12,
      limitArray: [12, 24, 48],
      headers: [
        { text: "No.", value: "nomor", width: "70px" },
        { text: "Tahun", value: "tahunkebutuhan" },
        { text: "Keterangan", value: "keterangan" },
        { text: "Detail", value: "detail" },
      ],
      filter: "ALL",
      filterOptions: [],
    };
  },
  computed: {
    infoListRKBMN() {
      return this.$store.getters.infoListRKBMN;
    },
  },
  created() {
    this.init();
    for (let i = 2025; i <= 2100; i++) {
      this.filterOptions.push("ALL");
      this.filterOptions.push(i.toString());
    }
  },
  methods: {
    init() {
      if (this.filter == "ALL") {
        this.params = {
          page: this.page,
          limit: this.limit,
        };
      } else {
        this.params = {
          page: this.page,
          limit: this.limit,
          tahunKebutuhan: this.filter,
        };
      }

      this.$store.dispatch("getInfoListRKBMN", this.params);
    },

    nextPage() {
      if (this.page + 1 <= this.infoListRKBMN.jumlahhalaman) {
        this.page += 1;
        this.init();
      }
    },

    prevPage() {
      if (this.page - 1 >= 1) {
        this.page -= 1;
        this.init();
      }
    },

    updateLimit(number) {
      this.limit = number;
      this.init();
    },

    toDetailPage(item) {
      this.$router.push({
        name: "d_rkakl",
        params: { tahun: item.tahunkebutuhan, id: item._id },
      });
    },
  },
};
</script>